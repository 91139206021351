import React, { useEffect, useState } from 'react'

export type FileExtensionType = 'gcode' | 'stl' | 'jpg' | 'jpeg' | 'png' | 'pdf' | 'other'

interface FileTagProps {
  type: FileExtensionType
}

const backgroundColourMap: Record<FileExtensionType, string> = {
  gcode: 'bg-file-gcode',
  stl: 'bg-file-stl',
  jpg: 'bg-file-image',
  png: 'bg-file-image',
  jpeg: 'bg-file-image',
  pdf: 'bg-file-pdf',
  other: 'bg-file-other',
}

const fileTypeMap: Record<FileExtensionType, string> = {
  gcode: 'gcode',
  stl: '3d',
  jpg: 'image',
  png: 'image',
  jpeg: 'image',
  pdf: 'pdf',
  other: 'other',
}

const FileTag = ({type}: FileTagProps) => {
  const [backgroundColour, setBackgroundColour] = useState<string>('bg-black')
  if (!(type in backgroundColourMap)) {
    type = 'other'
  }
  useEffect(() => {
    setBackgroundColour(backgroundColourMap[type])
  }, [type])

  return (
    <div className={`font-mono rounded-full leading-none p-1 w-11 min-w-11 text-center text-white text-xxs font-bold ${backgroundColour}`}>
      {fileTypeMap[type].toUpperCase()}
    </div>
  )
}

export default FileTag