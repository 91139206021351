import React, { useState, ChangeEvent, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import { Button } from "@/global-components/components/ui/button"
import { Input } from '@/global-components/components/ui/input'
import { Textarea } from '@/global-components/components/ui/textarea'
import { useToast } from "@/global-components/components/ui/use-toast"
import helpers from "@/global-components/components/helpers"
import { FilamentType } from "@/global-components/types"
import Filament from '@/global-components/components/bw/filament/Filament'
import Circle from '@uiw/react-color-circle';
import { AlertCircle, Download, Layers } from "lucide-react";
import { Spin } from "react-cssfx-loading";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/global-components/components/ui/dialog"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/global-components/components/ui/select"

import GcodeFileInfo from '@/components/productRelated/GcodeFileInfo';

import { useMutation, useQuery } from '@apollo/client';
import api from '@/global-components/api'
import { Label } from '@/global-components/components/ui/label';

interface ProductForm {
  productTitle: string;
  productDescription: string;
}


const PrintProduct = (props: any) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [open, setOpen] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [availableFilaments, setAvailableFilaments] = useState<FilamentType[]>([]);
  const [selectedFilamentId, setSelectedFilamentId] = useState<string | undefined>(undefined);
  const product: any = props.product;
  const gcodeFiles: any = props.gcodeFiles;
  const [gcodeFileIdToPrint, setGcodeFileIdToPrint] = useState<string | undefined>(undefined);
  const smallButton: boolean = props.smallButton ? true : false;
  const { toast } = useToast()
  const navigate = useNavigate();

  const filamentsQuery = useQuery(api.printers.queries.GET_AVAILABLE_FILAMENTS);

  const [createPrintRequest , { loading, error }] = useMutation(api.printRequests.mutations.CREATE_PRINT_REQUEST, {
    onCompleted: (result: any) => {
      
      if (result.printRequest.success) {
        setUploading(false);
        setOpen(false);
        toast({
          variant: 'success',
          title: 'Print sent.',
          description: product?.productTitle + ' was successfully requested for print and scheduled onto the printers',
          duration: 5000,
        })
        // optimizeScheduleFromRequest({variables: {requestId: result.printRequest.printRequest.requestId, forcePrint: true}})
      } else {
        toast({
          variant: "destructive",
          title: 'Something went wrong while creating a print request',
          description: result.printRequest.errors[0],
          duration: 8000,
        })
      }
    },
    onError: (err: any) => {
      setUploading(false);
      toast({
        variant: "destructive",
        title: 'Sorry, something is not working right now',
        description: err.message
      })
    }
  });

  const [optimizeScheduleFromRequest] = useMutation(api.printRequests.mutations.OPTIMIZE_SCHEDULE_FROM_REQUEST, {
    onCompleted: (result: any) => {
      if (result.optimizeScheduleFromRequest.success) {
        setUploading(false);
        setOpen(false);
        toast({
          variant: 'success',
          title: 'Print sent.',
          description: product?.productTitle + ' was successfully requested for print and scheduled onto the printers',
          duration: 5000,
        })
      } else {
        setUploading(false);
        toast({
          title: 'Something went wrong while optimizing the schedule',
          description: result.optimizeScheduleFromRequest.error
        })
      }
    },
    onError: (err: any) => {
      setUploading(false);
      toast({
        variant: "destructive",
        title: 'Sorry, something is not working right now while optimising',
        description: err.message,
        duration: 10000,
      })
    }
  })

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuantity(Number(event.target.value))
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setUploading(true);
    createPrintRequest({variables: {fileId: gcodeFileIdToPrint, filamentId: selectedFilamentId, factoryId: 1, quantity: quantity, productId: product.productId}});
  };


  useEffect(() => {
    if(filamentsQuery.data) {
      setAvailableFilaments(filamentsQuery.data.filament);
    }
  }, [filamentsQuery])


  return (
    <Dialog
      open={open}
      onOpenChange={(value: boolean) => {
        setOpen(value);
        if (props.onOpenChange) {
          props.onOpenChange(value);
        }
      }}
    >
      <DialogTrigger asChild>
        {smallButton ? (
          <Button
            variant="bwprimary"
            disabled
            size="sm"
            onMouseDown={props.onMouseDownCallBack}
            onMouseUp={props.onMouseUpCallBack}
            title="Printing is currently disabled."
          >
            {props.buttonTitle}
          </Button>
        ) : (
          <Button
            variant="bwprimary"
            disabled={props.disabled}
            onMouseDown={props.onMouseDownCallBack}
            onMouseUp={props.onMouseUpCallBack}
            title="Printing is currently disabled."
          >
            <Layers className="mr-2 h-4 w-4" /> {props.buttonTitle}
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="max-w-64">
        <DialogHeader>
          <DialogTitle>
            {product.status.toLowerCase() === "production" ? (
              <span className="font-normal">Print</span>
            ) : (
              <span className="font-normal">Test print</span>
            )}
            : <span className="font-bold">{product?.productTitle}</span>{" "}
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <div className="flex gap-4">
              <div className="flex flex-col gap-4 flex-1">
                <div>
                  <Select onValueChange={setGcodeFileIdToPrint}>
                    <SelectTrigger className="basis-4/6">
                      <SelectValue placeholder="Select GCode from Product" />
                    </SelectTrigger>
                    <SelectContent>
                      {gcodeFiles?.map((gcode: any, index: number) => (
                        <SelectItem value={gcode.fileId} key={index}>
                          <div className="flex items-center gap-2">
                            {gcode.fileName}
                          </div>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex gap-1">
                  <Select onValueChange={setSelectedFilamentId}>
                    <SelectTrigger className="basis-4/6">
                      <SelectValue placeholder="Select Filament" />
                    </SelectTrigger>
                    <SelectContent>
                      {availableFilaments.map(
                        (filament: FilamentType, index: number) => (
                          <SelectItem value={filament.filamentId} key={index}>
                            <div className="flex items-center gap-2">
                              <Filament
                                color={filament.colour.hexCode || "#333333"}
                                active={true}
                                small={true}
                              />{" "}
                              {filament.material.displayName} -{" "}
                              {filament.colour.displayName}
                            </div>
                          </SelectItem>
                        )
                      )}
                    </SelectContent>
                  </Select>
                  <Input
                    type="number"
                    disabled={product.status.toLowerCase() !== "production"}
                    value={quantity}
                    placeholder="Set Quantity"
                    onChange={handleQuantityChange}
                    className="basis-2/6"
                  />
                </div>
                <Select value="ssf">
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Please select a Location" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="ssf">BW London</SelectItem>
                  </SelectContent>
                </Select>
                <div
                  className={`${
                    quantity > 1 ? "duration-300" : "h-0 duration-300"
                  } overflow-hidden transition-all`}
                >
                  <div
                    className={`text-xs text-bw-green/30 font-bold pl-4 mb-1`}
                  >
                    Automation Settings
                  </div>
                  <div
                    className={`${
                      quantity > 1
                        ? "p-4 border border-bw-grey opacity-100"
                        : "h-0 max-h-0 p-0 border-none opacity-0"
                    } automation flex flex-col gap-4 transform-gpu scale-1 rounded-sm bg-bw-background-grey-lighter text-sm transition-all overflow-hidden ease-out-expo duration-300`}
                  >
                    <div className="relative w-full flex flex-col gap-1">
                      <Label
                        htmlFor="z-offset"
                        className="flex items-center justify-between gap-1 pr-2"
                      >
                        <div>
                          Z Adjust{" "}
                          <span className="opacity-50">
                            (Nozzle distance from bed)
                          </span>
                        </div>
                        <div className="flex items-center gap-1 text-ui-denial-red">
                          <AlertCircle className="h-3 w-3" strokeWidth={3} />
                          Careful
                        </div>
                      </Label>
                      <Input
                        id="z-offset"
                        type="number"
                        min={0.0}
                        max={1}
                        step={0.01}
                        placeholder="Cannot be below 0.0mm"
                        // value={
                        //   options.zAdjust !== undefined ? options.zAdjust : ""
                        // }
                        // onChange={(e) => {
                        //   setSelectedPreset(undefined);
                        //   const { value, min, max } = e.target;
                        //   setOptions((prev) => ({
                        //     ...prev,
                        //     zAdjust:
                        //       value === ""
                        //         ? 0.0
                        //         : Math.max(
                        //             Math.min(Number(value), Number(max)),
                        //             Number(min)
                        //           ),
                        //   }));
                        // }}
                      />
                    </div>
                    <div className="relative w-full flex flex-col gap-1">
                      <Label htmlFor="print-temperature">
                        Overwrite extruder temperature
                      </Label>
                      <Input
                        id="print-temperature"
                        type="number"
                        min={1}
                        max={300}
                        placeholder="GCode extruder temp in C"
                        // value={options.customPrintTemperature}
                        // onChange={(e) => {
                        //   setSelectedPreset(undefined);
                        //   const { value, min, max } = e.target;
                        //   setOptions((prev) => ({
                        //     ...prev,
                        //     customPrintTemperature:
                        //       value === ""
                        //         ? undefined
                        //         : Math.max(
                        //             Math.min(Number(value), Number(max)),
                        //             Number(min)
                        //           ),
                        //   }));
                        // }}
                      />
                    </div>
                    <div className="relative w-full flex flex-col gap-1">
                      <Label
                        htmlFor="extruder-speed"
                        className="flex items-center justify-between gap-1 pr-2"
                      >
                        Overwrite print speed w/ multiplier
                        <div className="flex items-center gap-1 text-ui-denial-red">
                          <AlertCircle className="h-3 w-3" strokeWidth={3} />
                          Careful
                        </div>
                      </Label>
                      <Input
                        id="extruder-speed"
                        type="number"
                        min={0.1}
                        max={2.5}
                        placeholder="Multiplier 0.1 - 2.5"
                        // value={options.extruderSpeedMultiplier}
                        // onChange={(e) => {
                        //   setSelectedPreset(undefined);
                        //   const { value, min, max } = e.target;
                        //   setOptions((prev) => ({
                        //     ...prev,
                        //     extruderSpeedMultiplier:
                        //       value === ""
                        //         ? undefined
                        //         : Math.max(
                        //             Math.min(Number(value), Number(max)),
                        //             Number(min)
                        //           ),
                        //   }));
                        // }}
                      />
                    </div>
                    <div className="relative w-full flex flex-col gap-1">
                      <Label htmlFor="knockoff-type">Knock-off type</Label>
                      <Select
                      // value={options.knockOffType}
                      // onValueChange={(value: string) => {
                      //   setSelectedPreset(undefined);
                      //   setOptions((prev) => ({
                      //     ...prev,
                      //     knockOffType: value as KnockOffType,
                      //   }));
                      // }}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Knock-Off Type" />
                        </SelectTrigger>
                        <SelectContent>
                          {/* {Object.entries(knockOffTypes).map(([key, value]) => {
                            return (
                              <SelectItem value={key} key={key}>
                                {value}
                              </SelectItem>
                            );
                          })} */}
                          {/* <SelectItem value="default">Default knock off</SelectItem>
                        <SelectItem value="default_with_shovel">Default with shovel</SelectItem>
                        <SelectItem value="lift_and_knock">Lift and knock off</SelectItem> */}
                        </SelectContent>
                      </Select>
                    </div>
                    {/* {options.knockOffType === "custom" && (
                      <div className={`relative flex items-center gap-1`}>
                        <div className="relative w-full max-w-sm flex flex-col gap-1">
                          <Label htmlFor="custom-knockoff-x">
                            Custom knockoff X
                          </Label>
                          <Input
                            id="custom-knockoff-x"
                            type="number"
                            min={0}
                            max={200}
                            step={0.1}
                            placeholder="X in mm"
                            value={
                              options.customKnockoffX !== undefined
                                ? options.customKnockoffX
                                : ""
                            }
                            onChange={(e) => {
                              setSelectedPreset(undefined);
                              const { value, min, max } = e.target;
                              setOptions((prev) => ({
                                ...prev,
                                customKnockoffX:
                                  value === ""
                                    ? undefined
                                    : Math.max(
                                        Math.min(Number(value), Number(max)),
                                        Number(min)
                                      ),
                              }));
                            }}
                          />
                        </div>
                        <div className="relative w-full max-w-sm flex flex-col gap-1">
                          <Label htmlFor="custom-knockoff-z">
                            Custom knockoff Z
                          </Label>
                          <Input
                            id="custom-knockoff-z"
                            type="number"
                            min={0}
                            max={150}
                            step={0.1}
                            placeholder="Z in mm"
                            value={
                              options.customKnockoffZ !== undefined
                                ? options.customKnockoffZ
                                : ""
                            }
                            onChange={(e) => {
                              setSelectedPreset(undefined);
                              const { value, min, max } = e.target;
                              setOptions((prev) => ({
                                ...prev,
                                customKnockoffZ:
                                  value === ""
                                    ? undefined
                                    : Math.max(
                                        Math.min(Number(value), Number(max)),
                                        Number(min)
                                      ),
                              }));
                            }}
                          />
                        </div>
                      </div>
                    )} */}
                    {/* <div
                      className={`relative w-full max-w-sm flex flex-col gap-1 ${
                        options.knockOffType === "lift_and_knock"
                          ? ""
                          : "hidden"
                      }`}
                    >
                      <Label htmlFor="custom-lift-inside">
                        Lift move inside part{" "}
                        <span className="opacity-50">(Default: 2.5mm)</span>
                      </Label>
                      <Input
                        id="custom-lift-inside"
                        type="number"
                        min={0}
                        max={5}
                        step={0.1}
                        placeholder="Y in mm"
                        value={
                          options.customLiftInsideY !== undefined
                            ? options.customLiftInsideY
                            : ""
                        }
                        onChange={(e) => {
                          setSelectedPreset(undefined);
                          const { value, min, max } = e.target;
                          setOptions((prev) => ({
                            ...prev,
                            customLiftInsideY:
                              value === ""
                                ? undefined
                                : Math.max(
                                    Math.min(Number(value), Number(max)),
                                    Number(min)
                                  ),
                          }));
                        }}
                      />
                    </div> */}
                    <div className="relative flex items-center gap-1">
                      <div className="relative w-full max-w-sm flex flex-col gap-1">
                        <Label htmlFor="custom-retract">
                          Retract{" "}
                          <span className="opacity-50">(Default: 2mm)</span>
                        </Label>
                        <Input
                          id="custom-retract"
                          type="number"
                          min={0}
                          max={8}
                          step={0.1}
                          placeholder="Length of retracting + deretracting filament before print starts"
                          title="Length of retracting + deretracting filament before print starts"
                          // value={
                          //   options.customRetractLength !== undefined
                          //     ? options.customRetractLength
                          //     : ""
                          // }
                          // onChange={(e) => {
                          //   setSelectedPreset(undefined);
                          //   const { value, min, max } = e.target;
                          //   setOptions((prev) => ({
                          //     ...prev,
                          //     customRetractLength:
                          //       value === ""
                          //         ? undefined
                          //         : Math.max(
                          //             Math.min(Number(value), Number(max)),
                          //             Number(min)
                          //           ),
                          //   }));
                          // }}
                        />
                      </div>
                      <div className="relative w-full max-w-sm flex flex-col gap-1">
                        <Label htmlFor="de-retract">
                          Additional deretract
                          <span className="opacity-50"></span>
                        </Label>
                        <Input
                          id="de-retract"
                          type="number"
                          min={-8}
                          max={8}
                          step={0.1}
                          placeholder="Deretract more than you retracted before a print starts"
                          title="Deretract more than you retracted before a print starts"
                          // value={
                          //   options.additionalDeretraction !== undefined
                          //     ? options.additionalDeretraction
                          //     : ""
                          // }
                          // onChange={(e) => {
                          //   setSelectedPreset(undefined);
                          //   const { value, min, max } = e.target;
                          //   setOptions((prev) => ({
                          //     ...prev,
                          //     additionalDeretraction:
                          //       value === ""
                          //         ? undefined
                          //         : Math.max(
                          //             Math.min(Number(value), Number(max)),
                          //             Number(min)
                          //           ),
                          //   }));
                          // }}
                        />
                      </div>
                    </div>
                    <div className="relative w-full flex flex-col gap-1">
                      <Label htmlFor="cool-bed-to">Cool bed inbetween cycles to (Recco: 35)</Label>
                      <Input
                        id="cool-bed-to"
                        type="number"
                        min={0}
                        max={60}
                        step={1}
                        placeholder='Bed temperature in Ceclius'
                        // value={options.coolBedTo !== undefined ? options.coolBedTo : ''}
                        // onChange={(e) => {
                        //   setSelectedPreset(undefined)
                        //   const {value, min, max} = e.target;
                        //   setOptions(prev => ({
                        //     ...prev, 
                        //     coolBedTo: value === '' ? undefined : Math.max(Math.min(Number(value), Number(max)), Number(min))
                        //   }));
                        // }}
                      />
                    </div>
                    {/* <div className='flex flex-col gap-0'>
                      { options.coolBedTo ?
                        <div className='flex flex-col gap-0'>
                          <div className={`flex items-center space-x-1 py-2 px-3 ${options.coolBedTo === undefined ? 'pointer-events-none opacity-30': ''}`}>
                            <Checkbox id="cool-bed-mid-print" checked={options.coolBedMidPrint} onCheckedChange={(checked) => {
                              setSelectedPreset(undefined)
                              setOptions(prev => ({...prev, coolBedMidPrint: checked as boolean}))
                            }} />
                            <label
                              htmlFor="cool-bed-mid-print"
                              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                              Start cooling bed mid print
                            </label>
                          </div>
                          <div className={`flex items-center space-x-1 py-2 px-3 ${options.skipWaitForBedTemp === undefined ? 'pointer-events-none opacity-30': ''}`}>
                            <Checkbox id="skip-wait-for-bed-temp" checked={options.skipWaitForBedTemp} onCheckedChange={(checked) => {
                              setSelectedPreset(undefined)
                              setOptions(prev => ({...prev, skipWaitForBedTemp: checked as boolean}))
                            }} />
                            <label
                              htmlFor="skip-wait-for-bed-temp"
                              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                              Don't wait after cycle
                            </label>
                          </div>
                        </div>
                        :
                        ''
                      }

                      <div className="flex items-center space-x-1 py-2 px-3">
                        <Checkbox id="mesh-bed-level" checked={options.levelEachCycle} onCheckedChange={(checked) => {
                          setSelectedPreset(undefined)
                          setOptions(prev => ({...prev, levelEachCycle: checked as boolean}))
                        }} />
                        <label
                          htmlFor="mesh-bed-level"
                          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          Mesh-bed-leveling between cycles
                        </label>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 mb-8 hidden">
            <div className="">
              <div className="text-xs text-bw-grey font-medium">
                Estimated Cost
              </div>
              0.00 £
            </div>
            <div className="">
              <div className="text-xs text-bw-grey font-medium">
                Estimated CO2e
              </div>
              0.00 kg
            </div>
            <div className="">
              <div className="text-xs text-bw-grey font-medium">
                Estimated Time Finished
              </div>
              n/a
            </div>
          </div>
          <DialogFooter>
            <div className="flex gap-1 items-center">
              {quantity > 1 && (
                <Button
                  className="flex gap-1 items-center"
                  disabled
                  variant="bwsecondary"
                >
                  <Download className="h-4 w-4" />
                  Download automation GCode
                </Button>
              )}
              <Button
                variant="bwprimary"
                className="flex gap-1"
                disabled={uploading || quantity > 1}
                type="submit"
              >
                {uploading ? (
                  <Spin
                    className="inline-spin h-4 w-4"
                    color="#36463D"
                    width="20px"
                    height="20px"
                    duration="0.3s"
                  />
                ) : null}
                Send{" "}
                {product.status.toLowerCase() !== "production"
                  ? "test print"
                  : null}{" "}
                to factory
              </Button>
            </div>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  ); 
}

export default PrintProduct;