import React, { useEffect, useState } from 'react'
import { Info } from "lucide-react";
import { useMutation } from '@apollo/client';

import { Button } from "@/global-components/components/ui/button"
import { useToast } from "@/global-components/components/ui/use-toast"
import { Dialog, DialogContent, DialogHeader, DialogFooter, DialogTitle, DialogTrigger } from "@/global-components/components/ui/dialog"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/global-components/components/ui/select"

import './QuickPrint.scss'
import PrintProduct from '../productRelated/printProduct/PrintProduct';

import api from '@/global-components/api'

const QuickPrint = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [fileDropped, setFileDropped] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const [uploadPrototype] = useMutation(api.products.mutations.UPLOAD_PROTOTYPE);
  const [createPrintRequest] = useMutation(api.printRequests.mutations.CREATE_PRINT_REQUEST);
  const [optimizeScheduleFromRequest] = useMutation(api.printRequests.mutations.OPTIMIZE_SCHEDULE_FROM_REQUEST);

  const { toast } = useToast()

  const sendFileToPrinters = () => {
    uploadPrototype({variables: {file: file}})
      .then(result => {
        
        if (result.data.uploadPrototype.success) {
          createPrintRequest({variables: {fileId: result.data.uploadPrototype.uploadedPrototype.file.fileId, filamentId: '1', factoryId: '3', quantity: 1}})
            .then(result => {
              
              if (result.data.printRequest.success) {
                optimizeScheduleFromRequest({variables: {requestId: result.data.printRequest.printRequest.requestId}})
                  .then(result => {
                    
                    if (result.data.optimizeScheduleFromRequest.success) {
                      toast({
                        title: 'Print sent.',
                        description: fileName + ' was successfully requested for print and scheduled onto the printers'
                      })
                      setFileDropped(false)
                    }
                  })
              }
            })
        }
      })
  }

  useEffect(() => {
    const handleDragEnter = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(false);
    };

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      setIsDragging(false);
      // Handle the file upload logic here
      if (e.dataTransfer?.files) {
        setFile(e.dataTransfer.files[0]);
        setFileName(e.dataTransfer.files[0]?.name);
        setFileDropped(true);
        
      }
    };

    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);

    return () => {
      // Cleanup listeners on component unmount
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
    };
  }, []);

  return (
    <div className={isDragging ? 'quick-print absolute top-0 left-0 w-screen h-screen hovering-file' : 'quick-print absolute top-0 left-0 w-screen h-screen'}>
      <div className='info absolute w-full flex justify-center items-center w-full h-full'>
        <div className='text-md text-bw-pale-sage flex gap-2 items-center bg-bw-highlight/90 pt-4 pb-4 pl-8 pr-8 rounded-full'>
          <Info className='h-4 w-4'/>Just drop GCode anywhere to print
        </div>
      </div>
      {/*<PrintProduct noButton quickPrint open={fileDropped} onOpenChange={(value: boolean) => setFileDropped(value)} />*/}
      <Dialog open={fileDropped} onOpenChange={setFileDropped}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle><span className='font-normal'>Prototype print:</span> <span className='font-bold'>{fileName ? fileName : null}</span></DialogTitle>
          </DialogHeader>
          <div>
            {/*<label htmlFor='location' className='text-xs text-bw-grey pl-2'>Location</label>*/}
            <Select name='location' value="ssf">
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Please select a Location" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="ssf">SSF PlusX</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <DialogFooter>
            <Button variant="bwprimary" onClick={sendFileToPrinters}>Send prototype print</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default QuickPrint;