// ProductsHeader.tsx
import React from 'react';
import { Button } from '@/global-components/components/ui/button';
import Combobox from '@/global-components/components/combobox/Combobox';
import { Switch } from '@/global-components/components/ui/switch';
import { Tabs, TabsList, TabsTrigger } from '@/global-components/components/ui/tabs';
import { Grip, List } from 'lucide-react';
import { ProductsViewType } from '@/global-components/types';

const ProductStatusFilterItems = [{ value: 'unapproved' }, { value: 'approved' }, { value: 'production' }];

interface ProductsHeaderProps {
  groupFilter: string;
  statusFilter: string;
  organisationFilter: string;
  groups: { groupTitle: string; groupId: string }[];
  organisations: { name: string; organisationId: string }[];
  groupByProjects: boolean;
  productsViewType: string;
  clearFilters: () => void;
  setStatusFilter: (value: string) => void;
  setGroupFilter: (value: string) => void;
  setOrganisationFilter: (value: string) => void;
  updateGroupByProjects: (value: boolean) => void;
  updateProductsViewType: (value: ProductsViewType) => void;
}

const ProductsHeader: React.FC<ProductsHeaderProps> = ({
  groupFilter,
  statusFilter,
  organisationFilter,
  groups,
  organisations,
  groupByProjects,
  productsViewType,
  clearFilters,
  setStatusFilter,
  setGroupFilter,
  setOrganisationFilter,
  updateGroupByProjects,
  updateProductsViewType,
}) => {
  return (
    <div className='flex justify-between mb-8 items-center pl-10'>
      <div className='flex gap-2 items-center'>
        <h1 className='text-2xl text-bw-green'>Your Products</h1>
      </div>
      <div className='flex items-center gap-12'>
        <div className='filter flex gap-2 items-center'>
          <div className='text-sm'>
            {groupFilter || statusFilter || organisationFilter ? (
              <Button variant='link' onClick={clearFilters}>
                Clear Filter
              </Button>
            ) : (
              <div className='opacity-30'>Filter</div>
            )}
          </div>
          <Combobox
            title='Status'
            items={ProductStatusFilterItems}
            value={statusFilter}
            capitalise
            selectCallback={(newValue: string) => setStatusFilter(newValue)}
          />

          <div className='filter-by-project'>
            {groups?.length ? (
              <Combobox
                title='Project'
                wide
                items={groups?.map((group) => ({ name: group.groupTitle, value: group.groupId }))}
                value={groupFilter}
                selectCallback={(newValue: string) => setGroupFilter(newValue)}
              />
            ) : null}
          </div>

          <div className='filter-by-organisation'>
            {organisations?.length ? (
              <Combobox
                title='Organisation'
                wide
                items={organisations?.map((org) => ({ name: org.name, value: org.organisationId }))}
                value={organisationFilter}
                selectCallback={(newValue: string) => setOrganisationFilter(newValue)}
              />
            ) : null}
          </div>
        </div>
        <div className='view-settings flex justify-end items-center gap-6'>
          <div className={`flex items-center gap-1 ${productsViewType === 'LIST' && 'pointer-events-none opacity-30'}`}>
            <span className='text-xs text-bw-green font-medium'>Project View</span>
            <Switch checked={groupByProjects} onCheckedChange={updateGroupByProjects} />
          </div>
          <Tabs
            defaultValue='GRID'
            value={productsViewType}
            className='w-max'
            onValueChange={(value: string) => updateProductsViewType(value as ProductsViewType)}
          >
            <TabsList className='bg-bw-grey'>
              <TabsTrigger value='GRID'>
                <Grip className='w-3 h-3' />
              </TabsTrigger>
              <TabsTrigger value='LIST'>
                <List className='w-3 h-3' />
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ProductsHeader;
