import React, { useRef, useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Canvas, ThreeEvent } from '@react-three/fiber';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OrbitControls, Center, Environment, Sphere, Text } from '@react-three/drei';
import { Perf } from 'r3f-perf'
import CameraFacingText from './CameraFacingText';
import { Spin } from "react-cssfx-loading";
import { BufferGeometry, Vector3, Vector2, PerspectiveCamera, Cache } from 'three'
import { useToast } from "@/global-components/components/ui/use-toast";
import api from '@/global-components/api'

import { CircleDot, X } from 'lucide-react';

import { Button } from "@/global-components/components/ui/button"
import { Input } from "@/global-components/components/ui/input"
import helpers from "@/global-components/components/helpers"
import { Marker } from "@/global-components/types"
import {
  Popover,
  PopoverContent
} from "@/global-components//components/ui/popover"
import useUserStore from '@/context/useUserStore';

type STLViewerProps = { 
  url: string
  fileName: string
  fileSize: number
  fileId?: string
  className?: string
  miniPreview?: boolean 
}


const STLViewer: React.FC<STLViewerProps> = ({ url, fileName, fileSize, fileId, className, miniPreview = false}) => {
  const meshRef = useRef<THREE.Mesh>(null!);
  const [stlUrl, setStlUrl] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(true);
  const [geometry, setGeometry] = useState<BufferGeometry | undefined>(undefined);
  const [cameraWasCenteredOnce, setCameraWasCenteredOnce] = useState<Boolean>(false);
  const [mesh, setMesh] = useState<any>(null);
  const [canvasState, setCanvasState] = useState<any>(null);

  const { user } = useUserStore()

  
  const [addMarkerPopoverState, setAddMarkerPopoverState] = useState<boolean>(false);
  const [addMarkerPopoverPosition, setAddMarkerPopoverPosition] = useState<Vector2>(new Vector2(0, 0))

  const [deleteMarkerPopoverState, setDeleteMarkerPopoverState] = useState<boolean>(false);
  const [deleteMarkerPopoverPosition, setDeleteMarkerPopoverPosition] = useState<Vector2>(new Vector2(0, 0))
  const [deleteMarkerMarkerId, setDeleteMarkerMarkerId] = useState<number | undefined>(undefined);
  const [openMarker, setOpenMarker] = useState<Marker | undefined>(undefined)

  const [markerHovered, setMarkerHovered] = useState<boolean>(false);
  const [scaleWhenHovered, setScaleWhenHovered] = useState<number>(1);
  const [markerScaleMultiplier, setMarkerScaleMultiplier] = useState<number>(1);
  const [markers, setMarkers] = useState<Marker[]>([])

  const [modelHovered, setModelHovered] = useState<boolean>(false);
  
  let showMarkerIndicatorTimeout: NodeJS.Timeout;
  const mousePositionOnMeshCache: Vector3 = new Vector3(0, 0, 0);
  const [hoveredPointMultiplier ,setHoveredPointMultiplier] = useState<number>(1);
  const [hoveredPointPosition, setHoveredPointPosition] = useState<Vector3>(new Vector3(0, 0, 0));
  const [clickedPointPosition, setClickedPointPosition] = useState<Vector3>(new Vector3(0, 0, 0));

  const mouseClickOnMeshMovementThreshold:number = 3;
  const [mousePressedAt, setMousePressedAt] = useState<Vector2 | undefined>(undefined);
  const [mouseDownOnMarker, setMouseDownOnMarker] = useState<boolean>(false);
  const { toast } = useToast()

  const [markerMessageInput, setMarkerMessageInput] = useState<string>('');
  const [creatingMarker, setCreatingMarker] = useState<boolean>(false);
  const markersQuery = useQuery(api.products.queries.GET_MARKERS_FOR_FILE, {
    variables: { fileId: fileId },
    pollInterval: 5000,
    skip: !fileId
  });
  const [createMarker, createMarkerResults] = useMutation(api.products.mutations.CREATE_MARKER_FOR_FILE);
  const [deleteMarker, deleteMarkerResult] = useMutation(api.products.mutations.DELETE_MARKER);

  const bufferToUint8Array = (buffer: ArrayBuffer) => {
    return new Uint8Array(buffer);
  };
  
  const uint8ArrayToBuffer = (uint8Array: Uint8Array) => {
    return uint8Array.buffer;
  };
  

  useEffect(() => {
    setStlUrl(url)
  }, [fileId])

  useEffect(() => {
    if (!stlUrl || !fileId) return;
  
    Cache.enabled = true;

    const loadSTLFromCache = () => {
      const cachedSTL = Cache.get(`stl_${fileId}`);
      if (cachedSTL) {
        const loader = new STLLoader();
        const geometry = loader.parse(cachedSTL);
        setGeometry(geometry);
        setLoading(false);
      } else {
        fetchSTLFromServer();
      }
    };

    const fetchSTLFromServer = () => {
      fetch(stlUrl)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          Cache.add(`stl_${fileId}`, arrayBuffer);
          loadSTL(arrayBuffer);
        })
        .catch(error => {
          console.log(error);
          toast({
            title: "An error loading the STL has occurred.",
            description: "Most likely '" + fileName + "' is corrupted. Please contact software@batch.works for help.",
            variant: 'destructive'
          });
        });
    };

    const loadSTL = (arrayBuffer: ArrayBuffer) => {
      const loader = new STLLoader();
      const geometry = loader.parse(arrayBuffer);
      setGeometry(geometry);
      setLoading(false);
    };

    loadSTLFromCache();
  }, [stlUrl]);
  
  useEffect(() => {   
    setMarkers(
      markersQuery?.data?.markersForFile?.map((marker: any) => {
        const newMarker: Marker = {
          id: marker.markerId,
          createdAt: marker.createdAt,
          createdBy: marker.createdBy,
          hoverScale: 1,
          message: marker.message,
          coordinates: {
            x: marker.xCoord,
            y: marker.yCoord,
            z: marker.zCoord,
          }
        }
        return newMarker
      })
    )
  }, [markersQuery.data, markersQuery.loading, markersQuery])

  const addMarkerToView = (marker: any) => {
    const newMarker: Marker = {
      id: marker.markerId,
      createdAt: marker.createdAt,
      hoverScale: 1,
      message: marker.message,
      createdBy: marker.createdBy,
      coordinates: {
        x: marker.xCoord,
        y: marker.yCoord,
        z: marker.zCoord,
      }
    }
    if (markers) {
      setMarkers([...markers, newMarker]);
    } else {
      setMarkers([newMarker]);
    }
  }

  const confirmCreateMarker = () => {
    setCreatingMarker(true);
    
    
    createMarker({variables: {fileId: fileId, xCoordinate: clickedPointPosition.x, yCoordinate: clickedPointPosition.y, zCoordinate: clickedPointPosition.z, message: markerMessageInput ? markerMessageInput : undefined}})
      .then((result: any) => {
        if (result.data.createMarker.success) {
          toast({
            title: "Marker successfully added",
            variant: 'success',
            duration: 3000
          })
          addMarkerToView(result.data.createMarker.newMarker);
        }
      })
      .catch((error: any) => {
        
      })
      .finally(() => {
        setCreatingMarker(false);
        setAddMarkerPopoverState(false)
        setMarkerMessageInput('');
      })
  }

  const confirmDeleteMarker = () => {
    deleteMarker({variables: {markerId: deleteMarkerMarkerId}})
      .then((result: any) => {
        if(result.data.deleteMarker.success) {
          toast({
            title: "Marker successfully deleted"
          })
          setMarkers(markers.filter((marker: Marker) => marker.id !== deleteMarkerMarkerId));
        }
      })
      .catch((error: any) => {
        
      })
      .finally(() => {
        setDeleteMarkerMarkerId(undefined);
        setOpenMarker(undefined)
        setDeleteMarkerPopoverState(false);
      })
  }

  const mouseOverMarkerHandler = (markerId: Number) => {
    const updatedMarkers: Marker[] = [...markers];
    updatedMarkers.forEach((marker:Marker) => {
      if (marker.id === markerId) {
        marker.hoverScale = 1.4;
      }
    })
    setMarkers(updatedMarkers);
    setMarkerHovered(true);
  }

  const mouseLeaveMarkerHandler = (markerId: Number) => {
    const updatedMarkers: Marker[] = [...markers];
    updatedMarkers.forEach((marker:Marker) => {
      if (marker.id === markerId) {
        marker.hoverScale = 1;
      }
    })
    setMarkers(updatedMarkers);
    setMarkerHovered(false);
  }

  const clickOnMarkerHandler = (event: ThreeEvent<MouseEvent>, markerId: number) => {
    setDeleteMarkerPopoverPosition(new Vector2(event.x, event.y));
    setDeleteMarkerPopoverState(true);
    setDeleteMarkerMarkerId(markerId);
    setOpenMarker(markers.find((marker: Marker) => marker.id === markerId))
    setMouseDownOnMarker(false);
  }

  const mouseDownOnMarkerHandler = (event: ThreeEvent<PointerEvent>) => {
    setMouseDownOnMarker(true);
  }

  const mouseDownOnMesh = (event: ThreeEvent<PointerEvent>) => {
    if (miniPreview) {
      return;
    }
    setMousePressedAt(new Vector2(event.x, event.y))
  }

  const mouseUpOnMesh = (event: ThreeEvent<PointerEvent>) => {
    if (mousePressedAt && !mouseDownOnMarker) {
      if(mousePressedAt.distanceTo(new Vector2(event.x, event.y)) < mouseClickOnMeshMovementThreshold) {
        setAddMarkerPopoverState(true);
        setAddMarkerPopoverPosition(new Vector2(event.x, event.y));
        const clickedPointPositionOnMesh: Vector3 = new Vector3(event.point.x, event.point.y, event.point.z);
        const directionToCamera = new Vector3().subVectors(canvasState.camera.position, clickedPointPositionOnMesh);
        // Normalize the direction
        directionToCamera.normalize();

        // Scale by the desired distance (e.g., 1 unit)
        directionToCamera.multiplyScalar(-0.5 * markerScaleMultiplier);

        // Compute the new position by moving the clicked point towards the camera by the scaled vector
        const markerPoint = new Vector3().subVectors(clickedPointPositionOnMesh, directionToCamera);
        setClickedPointPosition(markerPoint);
      }
    }
  }

  const mouseMoveOverMesh = (event: ThreeEvent<PointerEvent>) => {

  }

  const mouseEnterMesh = (event: ThreeEvent<PointerEvent>) => {
    setModelHovered(true)
  }

  const mouseOutOfMesh = (event: ThreeEvent<PointerEvent>) => {
    setModelHovered(false)
    setMousePressedAt(undefined);
  }

  const updateCamera = (radius: number, center: Vector3) => {
    if (canvasState) {
      const camera = canvasState.camera;      
      
      if (camera instanceof PerspectiveCamera) {
        const fov = camera.fov * (Math.PI / 180);
        let cameraZ = Math.abs(radius / 4 * Math.tan(fov * 2));

        cameraZ *= 1.5;  // add some extra space
        camera.position.z = cameraZ;

        // Update the camera
        camera.lookAt(center);
        camera.updateProjectionMatrix();
      }
      setCameraWasCenteredOnce(true);
    }
  }
  useEffect(() => {
    geometry?.computeBoundingSphere();
    if (geometry?.boundingSphere?.radius && !cameraWasCenteredOnce) {
      setMarkerScaleMultiplier(geometry?.boundingSphere?.radius / 100);
      updateCamera(geometry?.boundingSphere?.radius, geometry?.boundingSphere?.center);
    }
  }, [geometry, mesh, canvasState, loading]);
  

  if (loading) {
    return (
      <div className='flex items-center justify-center w-full h-full'>
        <Spin className="inline-spin h-4 w-4" color="#36463D" width="20px" height="20px" duration="0.3s" />
      </div>
    )
  } else if (fileSize > 15 * 1024 * 1024) {
    return (
      <div className='flex items-center justify-center w-full h-full'>
        <div className='text-sm'>
          Sorry, 
          {miniPreview ? ' the file ' : <a href={url} target='_blank' className=''> <span className='underline'>{fileName}</span> </a> } 
          is to big to preview.
        </div>
      </div>
    )
  } else {
    return (
      <div className={modelHovered ? (markerHovered ? 'w-full h-full cursor-pointer' : 'w-full h-full cursor-all-scroll') : 'w-full h-full'}>
        <Canvas shadows camera={{ position: [0, 0, 80], fov: 50 }} onCreated={(state: any) => setCanvasState(state)}
          style={{ height: '100%', width: '100%' }} className={className} frameloop='demand' dpr={1}>
          {/* <Perf position="top-left" /> */}
          <ambientLight intensity={0.1} />
          <Center position={[0, 0, 0]}>
            <mesh ref={meshRef} geometry={geometry} castShadow 
                  onUpdate={(self: any) => setMesh(self)} 
                  onPointerEnter={(event) => mouseEnterMesh(event)}
                  onPointerMove={(event) => mouseMoveOverMesh(event)}
                  onPointerDown={(event) => mouseDownOnMesh(event)}
                  onPointerUp={(event) => mouseUpOnMesh(event)}
                  onPointerOut={(event) => mouseOutOfMesh(event)}>
              <meshStandardMaterial metalness={0.1} roughness={0.5} />
            </mesh>
          </Center>
          {/*modelHovered ? 
            <Sphere args={[1 * markerScaleMultiplier * hoveredPointMultiplier, 10, 10]} 
            position={[hoveredPointPosition.x, hoveredPointPosition.y, hoveredPointPosition.z]}>
                <meshBasicMaterial color={'#D3B549'} />
    </Sphere> : null*/}
          {!miniPreview && markers?.map((marker:Marker, index: number) => 
            <Sphere args={[1 * markerScaleMultiplier * marker.hoverScale, 10, 10]} 
              position={[marker.coordinates.x, marker.coordinates.y, marker.coordinates.z]} 
              onPointerDown={(event) => mouseDownOnMarkerHandler(event)}
              onPointerEnter={(event) => mouseOverMarkerHandler(marker.id)}
              onPointerLeave={(event) => mouseLeaveMarkerHandler(marker.id)}
              onClick={(event) => clickOnMarkerHandler(event, marker.id)}>
              <meshBasicMaterial color={marker.hoverScale > 1 ? '#62E850' : '#62E850'} />
                {/*<CameraFacingText
                  scale={marker.hoverScale > 1 ? [2 * markerScaleMultiplier, 2 * markerScaleMultiplier, 2 * markerScaleMultiplier] : [3 * markerScaleMultiplier, 3 * markerScaleMultiplier, 3 * markerScaleMultiplier]}
                  position={[0, 3 * markerScaleMultiplier, 0]}
                  color="#36463D" // default
                  anchorX="center" // default
                  anchorY="middle" // default
                >
                  <meshBasicMaterial/>
                    {marker.hoverScale > 1 ? 'DELETE' : marker.id}
          </CameraFacingText>*/}
            </Sphere>
          )}
          <Environment preset={'warehouse'} blur={0.8} />
          <OrbitControls enablePan={true} enableZoom={!miniPreview} enableDamping={true} zoomSpeed={0.35} />
        </Canvas>
        <Popover open={addMarkerPopoverState}>
            <PopoverContent style={{position: 'fixed', left:addMarkerPopoverPosition.x, top:addMarkerPopoverPosition.y}}
                            className="w-max flex flex-col gap-4">
              <div className='flex flex-col gap-2'>
                <div className=''>
                  <Input
                    className="text-bw-green placeholder-gray-200"
                    placeholder="Your message ..."
                    type='text'
                    value={markerMessageInput}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMarkerMessageInput(event.target.value)}
                  />
                </div>
                <div className='flex gap-2 justify-end'>
                  <Button variant="bwsecondary" onClick={() => setAddMarkerPopoverState(false)}>Cancel</Button>
                  <Button variant="bwconfirm" className='gap-2' onClick={() => confirmCreateMarker()}><CircleDot className='w-4 h-4' />Create New Marker</Button>
                </div>
              </div>
            </PopoverContent>
        </Popover>
        <Popover open={deleteMarkerPopoverState}>
            <PopoverContent style={{position: 'fixed', left:deleteMarkerPopoverPosition.x, top:deleteMarkerPopoverPosition.y}}
                            className="flex flex-col gap-4">
              <div className='flex flex-col gap-4 w-auto'>
                {openMarker ?
                  <div className='flex flex-col gap-1 w-auto'>
                    <div className='text-xs text-bw-green/30 flex gap-2 w-max'>
                      {user?.userId === openMarker.createdBy?.userId ?
                        'You'
                        :
                        openMarker.createdBy?.firstName} on {helpers.formatDateToDDMMYY(openMarker.createdAt)}
                    </div>
                    <div className={openMarker.message ? 'w-max max-w-md' : 'w-max max-w-md opacity-30'}>
                      {openMarker.message ? openMarker.message : 'No message was added to this marker.'}
                    </div>
                  </div>
                : null}
                <div className='flex justify-end w-max self-end items-center'>
                  <Button variant="minimal" onClick={() => {
                    setDeleteMarkerPopoverState(false)
                    setOpenMarker(undefined)
                  }}>Close</Button>
                  <Button variant="destructiveminimal" onClick={() => confirmDeleteMarker()}>Delete</Button>
                </div>
              </div>
            </PopoverContent>
        </Popover>
      </div>
    )
  }
};

export default STLViewer;
