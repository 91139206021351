"use client"

import * as React from "react"
import {
  Boxes,
  Folder,
  LayoutGrid,
  Paperclip,
  Share2,
  User2,
  UserCircle2,
} from "lucide-react"

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut,
} from "@/global-components/components/ui/command"
import useProductStore from "@/context/useProductStore"
import { FileType, ProductGroup, ProductType } from "@/global-components/types"
import { useNavigate } from "react-router-dom"
import helpers from "@/global-components/components/helpers"
import FileTag, { FileExtensionType } from "@/global-components/components/bw/FileTag"

const FindAnything = () => {
  const navigate = useNavigate()
  const { products, productsSharedWithYou, groups } = useProductStore()
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        setOpen((open) => !open)
      }
    }

    document.addEventListener("keydown", down)
    return () => document.removeEventListener("keydown", down)
  }, [])

  const handleProductClick = (reference: string) => {
    navigate('/product/' + reference);
    setOpen(false)
  }
  
  const handleProjectClick = (id: string) => {
    navigate('/project/' + id);
    setOpen(false)
  }
  
  const handleGeneralLink = (url: string) => {
    navigate(url)
    setOpen(false)
  }

  return (
    <>
      <CommandDialog open={open} onOpenChange={setOpen} hideCloseButton>
        <CommandInput placeholder="Search for anything ..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup id="general-links">
            <CommandItem className="flex justify-between items-center p-2" onSelect={() => handleGeneralLink('/')}>
              <div className="font-bold flex items-center gap-1">
                <LayoutGrid className="h-4 w-4" /> All Products
              </div>
            </CommandItem>
            <CommandItem className="flex justify-between items-center p-2" onSelect={() => handleGeneralLink('/account')}>
              <div className="font-bold flex items-center gap-1">
                <UserCircle2 className="h-4 w-4" />Account
              </div>
            </CommandItem>
          </CommandGroup>
          
          {products.length > 0 &&
            <CommandGroup heading="Products">
              {products.map((product: ProductType) => {
                return (
                  <CommandItem key={product.productId} className="flex justify-between items-center p-2" onSelect={() => handleProductClick(product.productVersion[0].reference)}>
                    <div className="flex flex-col gap-0">
                      <div className="font-bold flex items-center gap-1">
                        <Boxes className="h-4 w-4 text-bw-grey" />
                        {product.productTitle}
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="flex items-center gap-1 text-xs">
                          <div>User:</div>
                          <div className="font-bold">{product.createdBy?.firstName}</div>
                        </div>
                        <div className="flex items-center gap-1 text-xs">
                          <div>Created at:</div>
                          <div className="font-bold">{helpers.formatDateToDDMMYY(product.createdAt)}</div>
                        </div>
                      </div>
                    </div>
                    <div className="font-xs opacity-30">{product.filemodelSet?.length} Files</div>
                  </CommandItem>
                )
              })}
            </CommandGroup>
          }

          {groups.length > 0 &&
            <CommandGroup heading="Projects">
              {groups.map((group: ProductGroup) => {
                return (
                  <CommandItem key={group.groupId} className="flex justify-between items-center p-2" onSelect={() => handleProjectClick(group.groupId)}>
                    <div className="flex flex-col gap-0">
                      <div className="font-bold flex items-center gap-1">
                        <Folder className="h-4 w-4 text-bw-grey" />
                        {group.groupTitle}
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="flex items-center gap-1 text-xs">                          
                          <div className="font-bold">
                            {products.filter((product: ProductType) => product.groupId.groupId === group.groupId).length} Products
                          </div>
                        </div>
                      </div>
                    </div>
                  </CommandItem>
                )
              })}
            </CommandGroup>
          }

          {productsSharedWithYou.length > 0 &&
            <CommandGroup heading="Shared with you">
              {productsSharedWithYou.map((product: ProductType) => {
                return (
                  <CommandItem key={product.productId} className="flex justify-between items-center p-2" onSelect={() => handleProductClick(product.productVersion[0].reference)}>
                    <div className="flex flex-col gap-0">
                      <div className="font-bold flex items-center gap-1">
                        <Share2 className="h-4 w-4 text-bw-grey" />
                        {product.productTitle}
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="flex items-center gap-1 text-xs">
                          <div>User:</div>
                          <div className="font-bold">{product.createdBy?.firstName}</div>
                        </div>
                        <div className="flex items-center gap-1 text-xs">
                          <div>Created at:</div>
                          <div className="font-bold">{helpers.formatDateToDDMMYY(product.createdAt)}</div>
                        </div>
                      </div>
                    </div>
                    <div className="font-xs opacity-30">{product.filemodelSet?.length} Files</div>

                  </CommandItem>
                )
              })}
            </CommandGroup>
          }

          {products.length > 0 &&
            <CommandGroup heading="Files">
              {products.map((product: ProductType) =>
                product.filemodelSet?.map((file: FileType) => (
                  <CommandItem
                    key={`${product.productId}-${file.fileId}`}
                    className="flex justify-between items-center p-2"
                    onSelect={() => handleProductClick(product.productVersion[0].reference)}
                  >
                    <div className="flex flex-col gap-0">
                      <div className="font-bold flex items-center gap-1">
                        <Paperclip className="h-4 w-4 text-bw-grey shrink-0" />
                        <FileTag type={helpers.extractFileTypeFromFilename(file.fileName) as FileExtensionType} />
                        {file.fileName}
                      </div>
                      <div className="text-xs">Part of product: <b>{product.productTitle}</b></div>
                    </div>
                  </CommandItem>
                ))
              )}
            </CommandGroup>          
          }
          <CommandSeparator />
        </CommandList>
      </CommandDialog>
    </>
  )
}

export default FindAnything