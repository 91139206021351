import { LinkType } from '@/global-components/types';
import React from 'react';
import { Button } from '../ui/button';
import { XCircle } from 'lucide-react';
import helpers from '../helpers';

interface LinkProps {
	link: LinkType;
	remove: (id: string) => void;
}
const Link = ({ link, remove }: LinkProps) => {
	return (
		<div
			className="link relative flex items-center cursor-pointer justify-between rounded-md border group/link border-bw-green/10 bg-white/70 p-4 hover:border-bw-green/20"
			onClick={() => helpers.openInNewTab(link.linkUrl)}
		>
			<div className="flex flex-col w-2/3">
				<div className="font-medium text-bw-green text-sm">{link.linkName}</div>
				<div className="text-bw-green/30 text-sm group-hover/link:text-bw-green/70 group-hover/link:underline">{link.linkUrl}</div>
			</div>
			<Button
				variant="destructiveoutline"
				size="smallicon"
				title="Delete Link"
				onClick={(e) => {
					e.stopPropagation();
					remove(link.id);
				}}
			>
				<XCircle className="h-4 w-4" />
			</Button>
		</div>
	);
};

export default Link;
