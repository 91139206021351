import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, createHttpLink, gql } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import api from '@/global-components/api'

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProductType } from './global-components/types';

console.log('Current environment: ', process.env.REACT_APP_BRANCH);


const httpLink = createUploadLink({
  uri: api.GRAPHQL_URI + 'api/graphql',
});


const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = api.user.local.getToken()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),  
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          productsForUser: {
            keyArgs: false, // This ensures the field is always considered the same
            merge(existing = [], incoming) {
              // Create a map of incoming product IDs
              const incomingProductIds = new Map();
              incoming.forEach((product: ProductType) => {
                incomingProductIds.set(product.productId, product);
              });

              // Filter out products that are not in the incoming list
              const merged = existing.filter((product: ProductType) => incomingProductIds.has(product.productId));

              // Add new incoming products that are not in the existing list
              incoming.forEach((product: ProductType) => {
                if (!merged.some((existingProduct: ProductType) => existingProduct.productId === product.productId)) {
                  merged.push(product);
                }
              });

              return merged;
            }
          }
        }
      }
    }
  }) 
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();