import useUserStore from '@/context/useUserStore'
import { Input } from '@/global-components/components/ui/input'
import React, { useState, useEffect } from 'react'

const MaterialsView = (): React.JSX.Element => {
  const { user } = useUserStore()

  return (
    <div className='view materials'>
      <div className="flex justify-between items-center w-full mb-4 pl-10">
        <h1 className='text-2xl text-bw-green flex gap-1 items-center'>
          Your Materials, {user?.firstName}
        </h1>
        <Input
          placeholder='Search materials'
          className='shrink w-min'
          />
      </div>
      <div className='text-xs text-bw-green/30 font-bold pl-4 mb-1'>Materials you can order</div>
      <div className='text-xs text-bw-green/30 font-bold pl-4 mb-1'>Materials you have ordered in the past</div>
    </div>
  )
}

export default MaterialsView