import React, { useState, useEffect, ReactNode, useMemo } from 'react';
import { Route, Routes, useNavigate, useLocation, Navigate, Outlet } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { Toaster } from "@/global-components/components/ui/toaster"

import './App.scss';
import api from '@/global-components/api'

import Home from './views/home/Home';
import Account from './views/account/Account';
import Login, { LoginProps } from './views/login/Login';
import Signup, { SignupProps } from './views/signup/Signup';
import VerifyAccountEmail from './views/verifyAccountEmail/VerifyAccountEmail'
import Product from './views/product/Product';
import Navbar, { NavbarProps } from './components/navbar/Navbar';
import QuickPrint from './components/quickPrint/QuickPrint';
import SetNewPasswordWithToken from './views/SetNewPasswordWithToken/SetNewPasswordWithToken';
// import UserInternetConnectionScore from '@/global-components/components/UserInternetConnectionScore'

import { useVerifyTokenAndGetUser} from './services/user'

import { User, UserPreferences } from '@/global-components/types'
import MaterialsView from './views/material/MaterialsView';
import SchoolView from './views/school/SchoolView';
import Project from './views/project/Project';
import helpers from './global-components/components/helpers';
import useUserStore from './context/useUserStore';
import FindAnything from './components/FindAnything';

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [navbarOpen, setNavbarOpen] = useState<boolean>(false)
  const { user, setUser, setUserPreferences, setLoggedIn, loggedIn } = useUserStore()
  const navigate = useNavigate();
  const location = useLocation();
  const verifyTokenAndGetUser = useVerifyTokenAndGetUser();


  useEffect(() => {
    setLoading(true)
    helpers.removeUnusedFilesFromLocalStorage()

    const handleNotLoggedIn = () => {
      if (location.pathname !== '/signup') navigate("/login");
    }
    const init = async ()  => { 
      if(api.user.local.isTokenSet()) {
        try {
          const userData: User = await verifyTokenAndGetUser(api.user.local.getToken())
          setUser(userData);
          setUserPreferences(userData.userPreferences)
          setLoggedIn(true);
        } catch (err) {
          navigate('/login')
          // handleNotLoggedIn();
        } finally {
          setLoading(false);
        }
      } else {
       //  handleNotLoggedIn();
        setLoading(false);
      }
    }

    init();
  }, [])

  const routes = () => {
    if (loggedIn) {
      return (
        <Routes>
          {/* Protected Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/account" element={<Account />} />
          <Route path="/product/:productRef" element={<Product />} />
          <Route path="/project/:groupRef" element={<Project />} />
          <Route path="/materials" element={<MaterialsView />} />
          <Route path="/school" element={<SchoolView />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify/:authToken" element={<VerifyAccountEmail />} />
          <Route path="/reset/:resetToken" element={<SetNewPasswordWithToken />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      );
    }
  }


  return (
    <div className={`dw overflow-x-hidden`}>
      {/* <UserInternetConnectionScore updateInterval={5000} /> */}
      {user?.isStaff ? <QuickPrint /> : null}
      <Navbar navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen}/>
      <FindAnything />
      <div className={`w-full h-full relative transition transform-gpu ${navbarOpen ? 'translate-x-64 duration-sidebarEnterContent ease-out-expo' : 'translate-x-0 duration-sidebarExitContent ease-in-out-expo'}`}>
        {!loading && routes()}
      </div>
      {/* {!loading && (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify/:authToken" element={<VerifyAccountEmail />} />
          <Route path="/reset/:resetToken" element={<SetNewPasswordWithToken />} />
          <Route element={<PrivateRoute/>}>
            <Route path="/" element={<Home />} />
            <Route path="/account" element={<Account />}/>
            <Route path="/product/:productRef" element={<Product />} />
            <Route path="/project/:groupRef" element={<Project />} />
            <Route path="/materials" element={<MaterialsView />} />
            <Route path="/school" element={<SchoolView />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
        )} */}
      <Toaster />
    </div>
  );
}

export default App;
