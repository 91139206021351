// React imports
import React, { useState, ChangeEvent } from 'react';
import { useMutation } from '@apollo/client';

// Context and API imports
import api from '@/global-components/api'

// Component imports
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/global-components/components/ui/dialog";
import { Button } from "@/global-components/components/ui/button";
import { Input } from "@/global-components/components/ui/input";
import { useToast } from "@/global-components/components/ui/use-toast";
import { XCircle } from "lucide-react";


const SendPasswordResetEmail = (props: any) => {
  const [open, setOpen] = useState(false)
  const [email, setEmail] = useState<string | null>(null)
  const [emailError, setEmailError] = useState<string | null>(null);
  const [sendPasswordResetEmail] = useMutation(api.user.mutations.SEND_PASSWORD_RESET_EMAIL);
  const { toast } = useToast()

  const handleSendSubmit = () => {
    sendPasswordResetEmail({ variables: { email: email }})
      .then((result : any) => {
        toast({
          title: "Successfully send reset email. Please check your inbox.",
          variant: 'success',
          duration: 2000,
        })
        setOpen(false);
        if(props.callback) {
          props.callback();
        }
      })
      .catch((error : any) => { })
  }

  const isEmailValid = (_email: string | null): boolean => {
    if (_email) {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(_email) ? true : false;
    } else {
      return false;
    }
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(isEmailValid(event.target.value) ? null : 'Invalid e-mail');
    if (isEmailValid(event.target.value)) {
      setEmailError(null);
    }
  }

  const handleEmailBlur = () => {
    setEmailError(isEmailValid(email) ? null : 'Invalid e-mail');
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
          <Button variant="link" size='xs' className='text-xs opacity-50 hover:opacity-100'>Forgot your password?</Button>
      </DialogTrigger>
      <DialogContent className='max-w-l'>
        <DialogHeader>
          <DialogTitle>Send a Password Reset Link</DialogTitle> 
        </DialogHeader>
        <Input type='text' name='email' placeholder="Your accounts email address" value={email ? email : ''} onChange={handleEmailChange} onBlurCapture={handleEmailBlur}/>
        <DialogFooter>
          <Button variant="bwsecondary" onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="bwprimary" disabled={emailError ? true : false} onClick={handleSendSubmit}>Send Reset Password Email</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default SendPasswordResetEmail;