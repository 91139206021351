import React, {useState, ChangeEvent, FormEvent, useEffect} from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Button } from "@/global-components/components/ui/button"
import { Input } from '@/global-components/components/ui/input'
import BwSymbol from '@/global-components/brand-assets/BwSymbol'
import GrowingLines from '@/components/GrowingLines'
import { Eye, EyeOff, Send } from "lucide-react"


import SendPasswordResetEmail from './SendResetPasswordEmail';

import './Login.scss'

import api from '@/global-components/api'
import { useToast } from "@/global-components/components/ui/use-toast";
import { Spin } from 'react-cssfx-loading';
import useUserStore from '@/context/useUserStore';
export type LoginProps = {
}

const Login = (props: LoginProps) =>  {
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const redirectParam = params.get('redirect')
    setRedirect(redirectParam ? redirectParam : '')
  }, [])
  const { toast } = useToast()
  const { setLoggedIn, setUser, loggedIn } = useUserStore()
  const [loading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [login] = useMutation(api.user.mutations.LOGIN, {
    onCompleted: data => {
      if (data?.tokenAuth?.token) {
        if(!data.tokenAuth?.user.verified) {
          setLoading(false);
          toast({
            title: "Login unsuccessful",
            description: "Please verify your account first. We have sent you an email with an activation link.",
            variant: 'destructive',
            duration: 8000
          })
          return;
        } else {
          setLoading(false);
          setLoggedIn(true);
          setUser({
            userId: data?.tokenAuth.user.userId,
            username: data?.tokenAuth.user.username,
            isStaff: data?.tokenAuth.user.isStaff,
            firstName: data?.tokenAuth.user.firstName,
            lastName: data?.tokenAuth.user.lastName,
            email: data?.tokenAuth.user.email,
            userPreferences: data?.tokenAuth.user.userpreferencesmodelSet[0]
          })

          api.user.local.setToken(data.tokenAuth.token);
          console.log('redirect ', redirect)
          if (redirect?.includes('gateway')) {
            window.location.href = 'https://gateway.batch.works'
          } else {
            navigate('/')
          }
        }
      } else {
        setLoading(false);
        toast({
          title: "Login unsuccessful",
          description: "We're really sorry, but either your email address or password is incorrect.",
          variant: 'destructive',
          duration: 7000
        })
      }
    },
    onError: err => {
      setLoading(false);
      toast({
        title: "Login unsuccessful",
        description: "We're really sorry, an unexpected error occurred. Please try again later.",
        variant: 'destructive',
        duration: 7000
      })
      console.error(err)
    }
  });

  const loginWithFormData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true)
    login({variables: {email: email.toLowerCase(), password: password}})
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const goToPreviousPageIfAvailable = (previousUrl: string) => {
    const referrer = document.referrer;
    console.log("referrer ", referrer)
    console.log("referrer ", referrer.includes(previousUrl))
    console.log("prev url: ", previousUrl)
  
    if (referrer.includes(previousUrl)) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };
  

  useEffect(() => {
    if (loggedIn) {
      navigate('/')
    }
  }, [loggedIn])

  
  return (
    <div className='view login flex flex-col items-center justify-center overflow-hidden relative h-[calc(100vh-50px)] max-h-[calc(100vh-50px)]'>
      <div className='absolute top-0 left-0 h-full w-full z-0 opacity-100 blur-2xl'>
        {/* <div className='relative moveforthandback'>
          <GrowingLines
            numLines={100}
            speed={0.5}
            thickness={2}
            thicknessRandomness={7}
            colors={['#62E850']}
            horizontalFraction={0.3}
            verticalSteps={5}
          />
        </div> */}
      </div>
      <BwSymbol className='w-32 h-32 mb-4 -mt-24 z-10 relative fill-bw-green' />  
      <h1 className='text-center font-bold z-10 relative text-bw-green'>Batch.Works</h1>
      <div className='text-center mb-8 text-xs italic text-bw-green opacity-30 z-10 relative'>Beta</div>
      <div className='login-container w-[250px] z-10 relative'> 
        <form onSubmit={loginWithFormData} className='mb-16 flex flex-col gap-1 w-full'>
          <Input type='email' name='email' className='bg-white/70' tabIndex={1} placeholder="Your email" value={email} onChange={handleEmailChange} />
          <div className='relative'>
          <Input type={passwordVisible ? 'text' : 'password'} tabIndex={2} className='bg-white/70' name='password' placeholder="Password" value={password} onChange={handlePasswordChange} />
            <Button type='button' variant='minimal' size='xs' className='absolute m-auto top-0 bottom-0 right-1' onClick={() => setPasswordVisible(!passwordVisible)}>
              {passwordVisible ? 
                <EyeOff className='h-4 w-4'/>
                : <Eye className='h-4 w-4'/>
              }
            </Button>
          </div>
          <div className='text-center' tabIndex={4}><SendPasswordResetEmail /></div>
          <Button variant="bwprimary" type="submit" className='mt-4 bg-bw-green text-white' tabIndex={3} disabled={loading}>
            {loading ?
              <Spin className="inline-spin h-4 w-4" color="#36463D" width="20px" height="20px" duration="0.3s" />
              :
              <>Login</>
              }
          </Button>
          <div className='text-sm flex justify-center items-center text-bw-green/50 mt-12'>
            <Button variant="link" onClick={() => navigate('/signup')}>Create Batch.Works account</Button>
          </div>
          {/* <div className='text-center mb-4 mt-6 text-xs text-bw-green opacity-30'>or</div>
          <Button variant="link" size='sm' onClick={() => navigate('/signup')}>Create a Batch.Works account</Button> */}

        </form>
        {/*<div className='flex flex-col gap-2'>
          <Button variant="link" size='sm' onClick={() => login({ variables: {email: 'alex@batch.works', password: 'testing1234!'}})}>Login A Dog - user</Button>
          <Button variant="link" size='sm' onClick={() => login({ variables: {email: 'marcel@hypersuper.uk', password: 'testing1234!'}})}>Login Gangsta - user</Button>
          <Button variant="link" size='sm' onClick={() => login({ variables: {email: 'liam@batch.works', password: 'testing1234!'}})}>Login Designer Leg 420 - staff</Button>
          <Button variant="link" size='sm' onClick={() => login({ variables: {email: 'marci@batch.works', password: 'testing1234!'}})}>Login Finji - superuser</Button>
        </div>*/}
      </div>
    </div>
  )
}

export default Login; 