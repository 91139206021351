import { FileType, ProductGroup, ProductType } from '@/global-components/types';
import { create } from 'zustand';

interface ProductState {
  products: ProductType[]
  setProducts: (products: ProductType[]) => void
  productsSharedWithYou: ProductType[]
  setProductsSharedWithYou: (productsSharedWithYou: ProductType[]) => void
  groups: ProductGroup[]
  setGroups: (groups: ProductGroup[]) => void
}

const useProductStore = create<ProductState>((set) => ({
  products: [],
  setProducts: (products: ProductType[]) => {
    set({ products })
  },
  productsSharedWithYou: [],
  setProductsSharedWithYou: (productsSharedWithYou: ProductType[]) => {
    set({ productsSharedWithYou })
  },
  groups: [],
  setGroups: (groups: ProductGroup[]) => {
    set({ groups })
  }
}))

export default useProductStore