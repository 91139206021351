import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client';
import { useToast } from "@/global-components/components/ui/use-toast";

// Context and API imports
import api from '@/global-components/api'

const VerifyAccountEmail = () => {
  const { authToken } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast()
  const [verifyToken] = useMutation(api.user.mutations.VERIFY_ACCOUNT);

  useEffect(() => {
    verifyToken({variables: {token: authToken}})
    .then(result => {
      
      if (result.data.verifyAccount.success) {
        
        navigate("/");
      } else {
        
        if (result.data.verifyAccount.errors?.nonFieldErrors[0]?.code === 'already_verified') {
          toast({
            title: "Already Verified",
          })
          navigate("/login");
        } else {
          toast({
            title: "Already Verified",
          })
          navigate("login");
        }
      }
    })
    .catch(err => {
      
    })
  }, [authToken])
  return (
    <div className='view verify-account-email'>

    </div>
  )
}

export default VerifyAccountEmail;