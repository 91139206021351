import useUserStore from '@/context/useUserStore';
import { Input } from '@/global-components/components/ui/input';
import React, { useState, useEffect } from 'react'

const SchoolView = (): React.JSX.Element => {
  const { user } = useUserStore();

  return (
    <div className='view school'>
      <div className="flex justify-between items-center w-full mb-4 pl-10">
        <h1 className='text-2xl text-bw-green flex gap-1 items-center'>
          Welcome to the DFAM Education portal, {user?.firstName}
        </h1>
        <Input
          placeholder='Search school'
          className='shrink w-min'
          />
      </div>
    </div>
  )
}

export default SchoolView