import { create } from 'zustand';

interface PreviewState {
  fileIdPreviewed: string | undefined
  setFileIdPreviewed: (fileId: string | undefined) => void
}

const usePreviewStore = create<PreviewState>((set) => ({
  fileIdPreviewed: undefined,
  setFileIdPreviewed: (fileIdPreviewed: string | undefined) => {
    set({ fileIdPreviewed })
  }
}))

export default usePreviewStore