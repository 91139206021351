import React, { FC } from 'react';

interface NavbarButtonProps {
	isActive: boolean;
}

const NavbarButton: FC<NavbarButtonProps> = ({ isActive }) => {
	return (
		<div
			className={`relative w-4 h-3 flex flex-col justify-around items-start focus:outline-none`}>
      <div className={`w-7 h-7 rounded-full absolute -left-1.5 transition-all duration-100 ease-in-out 
        ${isActive ? 'bg-bw-dark-tinted-green' : 'bg-transparent'}`}>
      </div>
			<div
				className={`w-full rounded-full h-[2px] bg-black transition-all duration-100 ease-in-out 
          ${isActive ? 'rotate-45 absolute bg-white' : ''}`}>
      </div>
			<div
				className={`w-4/5 rounded-full h-[2px] bg-black transition-opacity duration-50 ease-in-out 
          ${isActive ? 'opacity-0' : ''}`}>
      </div>
			<div
				className={`w-full rounded-full h-[2px] bg-black transition-all duration-100 ease-in-out 
          ${isActive ? '-rotate-45 absolute bg-white' : ''}`}>
      </div>
		</div>
	);
};

export default NavbarButton;
